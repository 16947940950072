export const contact = [
  {
    title: "사업관련 문의",
    // list: [
    //   {
    //     label: "marketing@henergy.xyz",
    //     icon: "mail.png",
    //     link: "mailto:marketing@henergy.xyz",
    //   },
    // ],
  },
  // {
  //   title: "협력사 모집공고",
  //   list: [
  //     {
  //       label: "모집공고 바로가기",
  //       icon: "arrow.png",
  //       link: `${window.location.origin}/notice`,
  //     },
  //   ],
  // },
];

export const sns = [
  {
    label: "blog",
    icon: "ico-blog.png",
    link: "https://blog.naver.com/h__energy",
  },
  {
    label: "인스타그램",
    icon: "ico-insta.png",
    link: "https://www.instagram.com/do_the_mohaet/",
  },
  {
    label: "페이스북",
    icon: "ico-facebook.png",
    link: "https://www.facebook.com/mohaet.official/",
  },
  {
    label: "유튜브",
    icon: "ico-youtube.png",
    link: "https://www.youtube.com/channel/UCTID047Mesr5A6cd2PzV9Wg",
  },
]

export const links = [
  {
    label: "모햇",
    icon: "mohaet_logo.png",
    link: "https://www.mohaet.com/",
  },
  {
    label: "김태양",
    icon: "solarbank-grey.svg",
    link: "https://www.solaroncare.com/",
  },
];

export const company = [
  { label: '사업자 등록번호', content: '103-86-01108' },
  { label: '대표', content: '함일한' },
  { label: '이메일', content: 'contact@henergy.xyz' },
  { label: '본사', content: '경상북도 포항시 남구 지곡동 176번지 체인지업 그라운드 609호' },
  { label: '서울지점', content: '서울특별시 강남구 삼성동 1번지 천마빌딩 505호' },
  { label: '기업부설연구소', content: '서울특별시 강남구 삼성동 1번지 천마빌딩 507호' },
  { label: '사업관련문의', content: '02-542-0327' },
]

export const bizs = [
  {
    label: "모햇",
    icon: "ico-mohaet.png",
    link: "https://www.mohaet.com/",
  },
  {
    label: "김태양",
    icon: "solaroncare-gray.png",
    link: "https://www.solaroncare.com/",
  },
]